@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script');
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

.hide-scrollbar {
	overflow: -moz-hidden-unscrollable;
	overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: DIN-Medium;
  font-size: 16px;
  background-color: #FAFAFA;
}

@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}

a:hover {
  text-decoration: none;
}

.type-face {
  font-family: 'Roboto Slab', serif;
}

.react-resizable {
  position: relative;
  background: rgba(139, 130, 91, 0.75);
}

.react-resizable-white {
  background: rgba(255,255,255,0.2) !important;
}

.react-resizable-white > textarea {
  border-color: #54BCEA !important;
}

button> div> span {
  font-family: DIN-Medium !important;
}

button> div> div {
  font-family: DIN-Medium !important;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.without-overflow > div {
  overflow-y: visible !important;
}

.document-pdf-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.document-pdf-preview-wrapper {
  height: 170px;
}

.text-field-core::before {
  border-bottom: 1px solid rgb(224, 224, 224) !important;
}

@font-face {
  font-family: 'DIN-Medium';
  font-style: normal;
  font-weight: normal;
  src:
    local('DIN Medium'),
    local('DIN-Medium'),
    url('./../assets/fonts/DIN-medium.otf') format('opentype');
  }

@font-face {
  font-family: 'DIN-Bold';
  font-style: normal;
  font-weight: bold;
  src: local('DIN Bold'),
       local('DIN-Bold'),
       url('./../assets/fonts/DIN-bold.ttf');
}
